import {
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar, IonToast, IonSpinner, IonToggle, IonRefresher, IonRefresherContent
} from '@ionic/react';
import { book, build, colorFill, grid, settings } from 'ionicons/icons';
import React, { useState, useEffect, Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './Style.css';
import SeatMap from './SeatMap';
import { create } from 'ionicons/icons';
import { RefresherEventDetail } from '@ionic/core';
import axios from 'axios';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class Tab2 extends Component<any, any> {

  constructor(props: {}) {
    super(props);
    this.state = {
      token: JSON.parse(window.localStorage.getItem('token') as string),
      settings: {
        today: false,
        map: false,
        success: false,
        error: false,
        loading: false,
        toast: false,
        toastMessage: null,
        edit: false,
        user: {},
        active: 'week',
        button: null,
      },
      booking: {
        seat: null,
        place: null,
        morning: null,
        afternoon: null
      }
    }

    // When initiated again
    this.props.history.listen((location, action) => {
      if (this.props.history.location.search === "?edit=today") {
        this.editBooking('today');
      }
      if (this.props.history.location.search === "?edit=tomorrow") {
        this.editBooking('tomorrow');
      }
    });
  }

  componentDidMount() {
    this.getUser()

    if (this.props.history.location.search === "?edit=today") {
      this.editBooking('today');
    }
    if (this.props.history.location.search === "?edit=tomorrow") {
      this.editBooking('tomorrow');
    }
  }

  refresh = (event: CustomEvent<RefresherEventDetail>) => {
    this.getUser();
    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  user() {
    return this.state?.settings?.user;
  }

  getUser() {
    let vm = this;

    axios.get(
      'https://fi-boarding.kssg.ch/api/v1/user',
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.state.settings.user = res.data

      if (!this.state.settings.active && res.data.current_day >= 1 && res.data.current_day <= 5 && this.state.settings.today) {
        this.state.settings.active = res.data.current_day
      }

      if (this.getPlace() === '3. Obergeschoss' || this.getPlace() === '4. Obergeschoss') {

        this.state.settings.map = true;
        this.state.settings.edit = true;
      }

      this.setState(this.state);
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }
        vm.state.settings.error = true;
        window.localStorage.removeItem('token');
        vm.setState(vm.state);
      })
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  refreshMap(today) {
    // console.log('refresh map');
    this.getUser();
  }

  selectSeat(seat) {
    if (!seat.enabled) {
      return
    }
    if (seat.booking.length > 0) {
      return
    }

    this.state.settings.user[this.getWeek()][this.state.settings.active].seat = seat;

    this.setState(this.state);
    //this.setState({ booking: { seat: seat } });
  }

  setActive(day) {
    this.state.settings.active = day;
    this.setState(this.state);

    let place = this.getPlace()

    if (place == '3. Obergeschoss' || place == '4. Obergeschoss') {
      this.state.settings.map = true;
    } else {
      this.state.settings.map = false;
    }
  }

  setPlace(place) {

    // console.log(place, this.getPlace(), 'setplace');

    if (!this.getPlace()) {
      //return;
    }

    if (place == '3. Obergeschoss' || place == '4. Obergeschoss') {
      this.state.settings.map = true;
    } else {
      this.state.settings.map = false;
    }

    // Dont Book Same Place
    if (place === this.getPlace()) {
      // console.log('same same');
      return;

    }

    this.state.settings.user[this.getWeek()][this.state.settings.active].place = place;

    this.setState(this.state);

    if (place != 'it\'s complicated') {
      this.saveBooking()
    }
  }

  setMorning(place) {
    this.state.settings.user[this.getWeek()][this.state.settings.active].settings.morning = place;
    // this.state.booking.morning = place;

    this.setState(this.state);

    // Save
    this.saveBooking('morning');

  }

  setAfternoon(place) {
    // this.state.booking.afternoon = place;
    this.state.settings.user[this.getWeek()][this.state.settings.active].settings.afternoon = place;

    this.setState(this.state);

    // Save
    this.saveBooking('afternoon');
  }

  showMap(value) {
    // console.log('setmap', value)
    // console.log('setmap', this.state.settings)
  }

  editBooking(date) {
    this.state.settings.edit = true;

    if (date == 'today') {
      this.state.settings.today = true;
      this.state.settings.active = this.state.settings.user.current_day;
    } else {
      this.state.settings.today = false;
      this.state.settings.active = 1;
      // console.log('set one');
    }

    this.setState(this.state);
  }

  closeToast() {
    this.state.settings.toast = false;
    this.setState(this.state);
  }

  showToast() {
    this.state.settings.toast = true;
    this.setState(this.state);
  }

  resetState() {
    this.state.booking.seat = null
    this.state.booking.place = null
    this.state.booking.morning = null
    this.state.booking.afternoon = null
    this.state.settings.loading = false
    this.state.settings.edit = false

    this.setState(this.state)
  }

  async saveBooking(halfday = null) {
    this.state.settings.loading = true;
    this.setState(this.state);
    let vm = this;

    // console.log('save booking', this.state.settings.user[this.getWeek()][this.state.settings.active], this.state.settings.active);
    await axios.post(
      'https://fi-boarding.kssg.ch/api/v1/bookings',
      { booking: this.activeBooking(), week: this.state.settings.today, day: this.state.settings.active, halfDay: halfday },
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      //this.resetState()
      vm.state.settings.loading = false
      vm.setState(vm.state);

      vm.getUser();

      if (this.state.settings.today) {
        // this.props.history.push('/dashboard?tab=today')
      } else {
        // this.props.history.push('/dashboard?tab=tomorrow')
      }
    })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
        }

        if (error.response.status === 422) {
          vm.state.settings.toastMessage = error.response.data.message;
          vm.state.settings.toast = true;
          vm.state.settings.loading = false;
          vm.setState(vm.state);
          return;
        }

        vm.state.settings.error = true;
        vm.state.settings.toastMessage = error.response.data.message;
        vm.state.settings.toast = true;
        vm.state.settings.loading = false;
       // window.localStorage.removeItem('token');
        vm.setState(vm.state);

        console.log(error.toJSON());
      })
  }
  isWeekend() {
    var today = new Date();
    if (today.getDay() == 6 || today.getDay() == 0) {
      return true;
    }
    return false;
  }
  getToday() {
    return "Diese Woche";
  }

  getHighlightedSeat(day) {

    // console.log(day, this.activeBooking().seats.filter((item) => {
    //   return item.pivot.settings == day
    // })[0].id)

    if (day) {
      let seats = this.activeBooking().seats.filter((item) => {
        return item.pivot.settings == day
      })
      if (seats.length > 0) {
        return seats[0].id
      }
    }

    return (this.state.settings.user[this.getWeek()][this.state.settings.active]?.seats && this.state.settings.user[this.getWeek()][this.state.settings.active].seats.length > 0) ? this.state.settings.user[this.getWeek()][this.state.settings.active].seats[0].id : null
  }

  getTomorrow() {
    var today = new Date();

    if (today.getDay() == 6 || today.getDay() == 5 || today.getDay() == 0) {
      return 'Nächste Woche';
    }

    return "Nächste Woche";
  }
  getTodaySmall() {
    return "diese Woche";
  }
  getTomorrowSmall() {
    var today = new Date();

    if (today.getDay() == 6 || today.getDay() == 5 || today.getDay() == 0) {
      return 'nächste Woche';
    }

    return "nächste Woche";
  }

  getActiveButtonStyle(day) {
    return { width: '20%', padding: '4px 8px', border: this.state.settings.active == day ? '1px solid black' : 'none', borderBottom: this.state.settings.active == day ? 'none' : '1px solid black', background: this.state.settings.active == day ? 'white' : '#f3f3f3' }
  }

  getWeek() {
    return this.state.settings.today ? 'thisWeek' : 'nextWeek'
  }

  activeBooking() {
    // console.log(this.state.settings.user[this.getWeek()][this.state.settings.active])
    return this.state.settings.user[this.getWeek()][this.state.settings.active]
  }

  activeButton(place) {
    // Week
    if (this.state.settings.active == 'week' && (this.state.settings.user[this.state.settings.today ? 'this_week_description' : 'next_week_description'].includes(place))) {
      return true;
    }

    // Day
    return this.state.settings.user[this.getWeek()] && this.getPlace() == place
  }

  getPlace() {
    if (this.state.settings.active == 'week' && (this.state.settings.user[this.state.settings.today ? 'this_week_description' : 'next_week_description'].includes('3. Obergeschoss'))) {
      return '3. Obergeschoss';
    }

    if (this.state.settings.active == 'week' && (this.state.settings.user[this.state.settings.today ? 'this_week_description' : 'next_week_description'].includes('4. Obergeschoss'))) {
      return '4. Obergeschoss';
    }

    return this.state.settings.user[this.getWeek()][this.state.settings.active]?.place
  }

  render() {
    const user = this.user();
    const booking = this.state.booking;

    if (!this.state.token) {
      return <Redirect to='/login' />
    }

    // if (this.state.settings.success) {
    //   return <Redirect to='/dashboard' />
    // }

    return (
      <>
        <IonPage>

          <IonToast
            color="danger"
            isOpen={this.state.settings.toast}
            onDidDismiss={() => this.closeToast()}
            message={this.state.settings.toastMessage}
            duration={2000}
            position="top"
          />

          <IonContent>
            <div className="senn-header tab-2">
              <a className="senn-logo" href="/dashboard"><img src="/img/kssg.png" style={{ height: '100' }} width="100px" /></a>
            </div>

            <IonRefresher slot="fixed" onIonRefresh={this.refresh}>
              <IonRefresherContent refreshingSpinner="dots"></IonRefresherContent>
            </IonRefresher>

            <div>
              <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.editBooking('today')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getToday()}</IonCardSubtitle>
                  <IonCardTitle className="card-title">

                    <div className="card-title-seat">
                      {this.state.settings.edit && this.state.settings.today && user[this.getWeek()] && user[this.getWeek()][this.state.settings.active] &&
                        < div >
                          {user[this.getWeek()][this.state.settings.active]['titel']}
                        </div>
                      }
                      {!this.state.settings.today && this.state.settings.edit &&
                        <div>
                          {user['this_week_description']}
                        </div>
                      }
                      {this.state.settings.active == 'week' && this.state.settings.today && this.state.settings.edit &&
                        <div>
                          {user['this_week_description']}
                        </div>
                      }
                      {!this.state.settings.edit &&
                        <div>
                          {user['this_week_description']}
                        </div>
                      }
                    </div>
                  </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>



              {!this.state.settings.today &&
                <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.editBooking('tomorrow')}>
                  <IonCardHeader>
                    <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                    <IonCardTitle className="card-title">

                      <div>

                        {/* {capitalize(user.booking.place)} */}
                      </div>

                      <div className="card-title-seat">
                        {this.state.settings.edit && !this.state.settings.today && user[this.getWeek()] &&
                          <div>
                            {user[this.getWeek()][this.state.settings.active]['titel']}
                          </div>
                        }
                        {this.state.settings.today && this.state.settings.edit &&
                          <div>
                            {user['next_week_description']}
                          </div>
                        }
                        {this.state.settings.active == 'week' && !this.state.settings.today && this.state.settings.edit &&
                          <div>
                            {user['next_week_description']}
                          </div>
                        }
                        {!this.state.settings.edit &&
                          <div>
                            {user['next_week_description']}
                          </div>
                        }
                      </div>
                    </IonCardTitle>
                    <IonIcon className="card-icon" icon={create}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              }

            </div>

            {(!this.state.settings.success && this.state.settings.edit) &&
              <div className="content">
                <h1 className="text-black text-4xl text-90 font-light my-8">
                  Wo wirst du {this.state.settings.today ? ' ' + this.getTodaySmall() + ' ' : ' ' + this.getTomorrowSmall() + ' '} arbeiten?
            </h1>
                <div style={{ display: 'flex', marginBottom: '20px' }}>

                  <button onClick={() => this.setActive('week')} color="light" style={{ width: '20%', padding: '4px 8px', border: this.state.settings.active == 'week' ? '1px solid black' : 'none', borderBottom: this.state.settings.active == 'week' ? 'none' : '1px solid black', background: this.state.settings.active == 'week' ? 'white' : '#f3f3f3', }}>Woche</button>
                  <button disabled={this.state.settings.today && user.current_day > 1} onClick={() => this.setActive(1)} color="light" style={this.getActiveButtonStyle('1')}>Mo</button>
                  <button disabled={this.state.settings.today && user.current_day > 2} onClick={() => this.setActive(2)} color="light" style={this.getActiveButtonStyle('2')}>Di</button>
                  <button disabled={this.state.settings.today && user.current_day > 3} onClick={() => this.setActive(3)} color="light" style={this.getActiveButtonStyle('3')}>Mi</button>
                  <button disabled={this.state.settings.today && user.current_day > 4} onClick={() => this.setActive(4)} color="light" style={this.getActiveButtonStyle('4')}>Do</button>
                  <button disabled={this.state.settings.today && user.current_day > 5} onClick={() => this.setActive(5)} color="light" style={this.getActiveButtonStyle('5')}>Fr</button>
                </div>

                <IonButton className="custom-button" expand="block"
                  color={this.activeButton('3. Obergeschoss') ? 'secondary' : 'light'}
                  onClick={() => this.setPlace('3. Obergeschoss')}>3. Obergeschoss</IonButton>

                <IonButton className="custom-button" expand="block"
                  color={this.activeButton('4. Obergeschoss') ? 'secondary' : 'light'}
                  onClick={() => this.setPlace('4. Obergeschoss')}>4. Obergeschoss</IonButton>

                <IonButton className="custom-button" expand="block" color={this.activeButton('home') ? 'secondary' : 'light'} onClick={() => this.setPlace('home')}>Home Office</IonButton>

                <IonButton className="custom-button" expand="block" color={this.activeButton('unterwegs') ? 'secondary' : 'light'} onClick={() => this.setPlace('unterwegs')}>unterwegs</IonButton>

                <IonButton className="custom-button" expand="block" color={this.activeButton('frei') ? 'secondary' : 'light'} onClick={() => this.setPlace('frei')}>frei</IonButton>

                {(this.state.settings.active != 'week') &&
                  <IonButton className="custom-button" expand="block"
                    color={this.activeButton('Halbtage') ? 'secondary' : 'light'}
                    onClick={() => this.setPlace('Halbtage')}>Halbtage</IonButton>
                }

                {this.activeButton('Halbtage') &&
                  <div>
                    <h2 className="text-black text-2xl text-90 font-light my-8">
                      Wo wirst du am Morgen arbeiten?
                    </h2>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.morning == '3. Obergeschoss' ? 'secondary' : 'light'} onClick={() => this.setMorning('3. Obergeschoss')}>3. Obergeschoss</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.morning == '4. Obergeschoss' ? 'secondary' : 'light'} onClick={() => this.setMorning('4. Obergeschoss')}>4. Obergeschoss</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.morning == 'home' ? 'secondary' : 'light'} onClick={() => this.setMorning('home')}>Home Office</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.morning == 'unterwegs' ? 'secondary' : 'light'} onClick={() => this.setMorning('unterwegs')}>unterwegs</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.morning == 'frei' ? 'secondary' : 'light'} onClick={() => this.setMorning('frei')}>frei</IonButton>

                    {(this.activeBooking().settings?.morning == '3. Obergeschoss' || this.activeBooking().settings?.morning == '4. Obergeschoss') &&
                      <div>
                        <SeatMap
                          viewOnly={false}
                          today={this.state.settings.today}
                          day={this.state.settings.active}
                          user={this.state.settings.user}
                          halfDay={'morning'}
                          place={this.activeBooking().settings?.morning}
                          seat={this.state.settings.user[this.getWeek()][this.state.settings.active]?.seat}
                          highlight={this.getHighlightedSeat('morning')}
                          selectSeat={this.selectSeat.bind(this)}
                          refreshMap={this.refreshMap.bind(this)}
                          booking={this.state.settings.user[this.getWeek()][this.state.settings.active]}
                        />
                      </div>
                    }

                    <h2 className="text-black text-2xl text-90 font-light my-8">
                      Wo wirst du am Nachmittag arbeiten?
                    </h2>

                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.afternoon == '3. Obergeschoss' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('3. Obergeschoss')}>3. Obergeschoss</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.afternoon == '4. Obergeschoss' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('4. Obergeschoss')}>4. Obergeschoss</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.afternoon == 'home' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('home')}>Home Office</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.afternoon == 'unterwegs' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('unterwegs')}>unterwegs</IonButton>
                    <IonButton className="custom-button" expand="block" color={this.activeBooking().settings?.afternoon == 'frei' ? 'secondary' : 'light'} onClick={() => this.setAfternoon('frei')}>frei</IonButton>

                    {(this.activeBooking().settings?.afternoon == '3. Obergeschoss' || this.activeBooking().settings?.afternoon == '4. Obergeschoss') &&
                      <div>
                        <SeatMap
                          viewOnly={false}
                          today={this.state.settings.today}
                          day={this.state.settings.active}
                          user={this.state.settings.user}
                          place={this.activeBooking().settings?.afternoon}
                          halfDay={'afternoon'}
                          seat={this.state.settings.user[this.getWeek()][this.state.settings.active]?.seat}
                          highlight={this.getHighlightedSeat('afternoon')}
                          selectSeat={this.selectSeat.bind(this)}
                          refreshMap={this.refreshMap.bind(this)}
                          booking={this.state.settings.user[this.getWeek()][this.state.settings.active]}
                        />
                      </div>
                    }

                  </div>
                }


                {(this.state.settings.edit) &&
                  <div>
                    {(this.state.settings.map) &&
                      <div>
                        <SeatMap
                          viewOnly={false}
                          today={this.state.settings.today}
                          day={this.state.settings.active}
                          user={this.state.settings.user}
                          place={this.getPlace()}
                          seat={this.state.settings.user[this.getWeek()][this.state.settings.active]?.seat}
                          highlight={(this.state.settings.user[this.getWeek()][this.state.settings.active]?.seats && this.state.settings.user[this.getWeek()][this.state.settings.active].seats.length > 0) ? this.state.settings.user[this.getWeek()][this.state.settings.active].seats[0].id : null}
                          selectSeat={this.selectSeat.bind(this)}
                          refreshMap={this.refreshMap.bind(this)}
                          booking={this.state.settings.user[this.getWeek()][this.state.settings.active]}
                        />
                      </div>
                    }
                  </div>
                }
                {this.state.settings.loading &&
                  <IonSpinner name="dots" />
                }
              </div>
            }

            {this.state.settings.today &&
              <IonCard className="no-radius" color="secondary" button={true} onClick={() => this.editBooking('tomorrow')}>
                <IonCardHeader>
                  <IonCardSubtitle>{this.getTomorrow()}</IonCardSubtitle>
                  <IonCardTitle className="card-title">

                    <div>
                      {/* {capitalize(user.booking.place)} */}
                    </div>


                    <div className="card-title-seat">
                      {user['next_week_description']}

                    </div>
                  </IonCardTitle>
                  <IonIcon className="card-icon" icon={create}></IonIcon>
                </IonCardHeader>
              </IonCard>
            }


          </IonContent>
        </IonPage>
      </>
    );
    // <span onClick={() => {this.state.showMap = true; this.setState(this.state); }}>Platz selber wählen</span>
  }
};
